import React from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Article from '../components/News/Article';
import { useSiteContext } from '../components/SiteContext';
import withApollo from '../lib/apollo';
import throw404 from '../lib/throw404';

const NewsArticle = ({ router }) => {
  const {
    hasLocalNews,
  } = useSiteContext();

  const { id, path } = router.query;

  if (!id && !hasLocalNews) {
    return throw404();
  }

  const articleProperties = id ? { id: Number(id) } : { path };

  return (
    <Layout>
      <Article {...articleProperties} />
    </Layout>
  );
};

NewsArticle.propTypes = {
  router: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
    }),
  }),
};

NewsArticle.defaultProps = {
  router: {},
};

const NewsArticleWithRouter = withRouter(NewsArticle);

NewsArticleWithRouter.getLayout = (page) => page;

export default withApollo(NewsArticleWithRouter);
